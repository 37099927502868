@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Barlow+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Ephesis&family=Smooch+Sans:wght@100;200;300;400;500;600&family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Urbanist', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

code {
  font-family: 'Urbanist', sans-serif;
}

.background-table {
  background-color: rgb(244, 244, 244);
}
.modal-header {
  text-align: center;
}

.modal-title {
  margin: 0 auto;
}

@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@100;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@100;400;500;600;700&display=swap");

.modal-container {
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}
.modal-container-closed {
  display: none;
  background-color: rgba(0, 0, 0, 0.3);
  align-items: center;
  justify-content: center;
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}
.alert {
  background-color: #ffff;
  margin: 5%;
  width: 600px;
  max-width: 100%;
  padding: 30px 50px;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgb(0, 0, 0.2);
  text-align: center;
}
.cerrar-modal {
  align-items: center;
  padding: 1px;
  float: center;
}
.hide {
  display: none;
}

* {
  text-align: center;
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.custom-file-upload:hover {
  background-color: #f5f5f5;
}

.upload-icon {
  margin-right: 5px;
}

.menu-item {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.custom-file-upload:hover {
  background-color: #e0e0e0;
}

.upload-text {
  margin: 0;
}

.upload-icon {
  margin-right: 5px;
}

.menu-item-content {
  flex: 1;
  margin-right: 10px;
}

.primary-btn {
  margin-bottom: 10px;
}

.product-info h4 {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.product-info input {
  margin-right: 5px;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid #ccc;
}

.edit-icon {
  font-size: 18px;
  margin-left: 5px;
  color: #555;
  cursor: pointer;
}
.price-trash {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: end;
}

.product-description-input {
  margin-bottom: 0;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid #ccc;
}

.actions {
  margin-left: 20px;
}

/* ----------------------------- Nuevos estilos ---------------------------------- */
/* ----------------------------- Nuevos estilos ---------------------------------- */
/* ----------------------------- Nuevos estilos ---------------------------------- */
/* ----------------------------- Nuevos estilos ---------------------------------- */

/* ----------------------------- ESTILOS BARRA DE CARGA IMAGEN ---------------------------------- */

.progress-bar {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 400px;
  background-color: #b6b6b6 !important;
  border-radius: 4px;
  padding: 4px;
  z-index: 9999;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Agregar sombra */
}

.progress-bar-inner {
  height: 10px;
  background-color: #007bff;
  border-radius: 4px;
  transition: width 0.3s ease-in-out;
  background: linear-gradient(to right, #007bff, #0056b3);
}

.progress-bar-enter {
  opacity: 0;
}

.progress-bar-enter-active {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.progress-bar-exit {
  opacity: 1;
}

.progress-bar-exit-active {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

/* ---------------- NAV BAR --------------------- */

.nav-bar {
  background-color: #232121;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #ffffff;
}
.navbar-logo {
  width: 70px;
  height: auto;
  margin-right: 10px;

}

.menu-title {
  font-size: xx-large;
  font-weight: 500;
}

.view-url {
  background-color: #fdc029 !important ;
  color: white !important;
}

.productEditorStyles {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 10px;
  overflow: hidden;
  background-color: rgb(231, 231, 231);
  border-radius: 14px;
  box-shadow: 6px 8px 13px -5px rgba(99, 99, 99, 0.75);
  -webkit-box-shadow: 6px 8px 13px -5px rgba(99, 99, 99, 0.75);
  -moz-box-shadow: 6px 8px 13px -5px rgba(99, 99, 99, 0.75);
  margin: 20px 0px;
}

.menu-qr {
  margin: 10px;
}

/* --------------------------- IMAGE BOX ------------------------------ */

.imageEditorStyles {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
  width: 20%;
  height: auto;
}
.preview-image-modal {
  width: 100%;
  height: 100%;
}
.preview-image-modal-ref {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 10px;
}

.preview-image-modal-ref img {
  max-height: 200px;
  background-size: cover;
  background-position: center;
}

.image-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.file-upload-icon {
  cursor: pointer;
}
.expand-image-icon {
  cursor: pointer;
}
.trash-image-icon {
  cursor: pointer;
}

/* --------------------------- DETAILS BOX ------------------------------ */

.detailsEditorStyles {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 65%;
  padding: 5px 0px;
}

.product-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.product-info p {
  line-height: normal;
  font-size: medium;
  margin-top: 10px;
  margin-bottom: 10px;
}

.product-info-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 5px;
}

.product-info-div span {
  margin-right: 30px;
  width: 20%;
}
.product-info-div input {
  width: 80%;
  box-shadow: 10px 8px 13px -12px rgba(161, 161, 161, 1);
  -webkit-box-shadow: 10px 8px 13px -12px rgba(161, 161, 161, 1);
  -moz-box-shadow: 10px 8px 13px -12px rgba(161, 161, 161, 1);
}

.product-name-input-ref {
  width: 100%;
  border-radius: 7px;
}

.price-input-ref {
  width: 70%;
  margin: 5px;
  border-radius: 10px;
  border: 1px solid rgb(183, 180, 180);
}

.product-description-input-ref {
  width: 100%;
  border-radius: 7px;
}

.product-price-ref {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* --------------------------- CATEGORY INFO ------------------------------ */

.menu-col-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 5px;
  font-size: 25px;
  font-weight: 1000;
  letter-spacing: 1px;
  font-family: 'Urbanist', sans-serif;
}

.add-price-type {
  display: flex;
  justify-content: center;
  align-items: end;
}

.category-info .infos {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.category-info .infos div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.trash {
  margin-left: 30px;
}

.category-info .product-info {
  width: 100%;
}
.category-info .product-info-div {
  width: 100%;
}

.category-info span {
  font-size: small;
  margin-right: 20px;
  width: 30%;
}

.category-info input {
  width: 70%;
}

.add-button {
  display: inline-block;
}

.add-button-product {
  margin-bottom: 20px;
}

/* --------------------------- EDITORACTIONS BOX ------------------------------ */

.editorActionsStyles {
  display: flex;
  height: 220px;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  width: 15%;
  padding: 10px;
}
.editorActionsStyles button {
  width: 80px;
  height: 55px;
}

/* ---------------- NOMBRES DE CATEGORIAS --------------------- */
.category-names {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.language-name {
  display: inline;
  flex-wrap: wrap;
  margin: 10px;
  font-size: large;
  padding: 0 20px;
  flex: 1 0 100%;
}

.price-type-editor {
  margin-top: 20px;
}

.price-types {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px;
  font-size: large;
  flex: 1 0 100%;
  text-align: center;
}

.price-types-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.file-upload-icon input[type="file"] {
  border: 1px solid black;
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

/* ---------------- CENTRA NOMBRE DE SUBCATEGORIA --------------------- */

.level-0 {
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  padding: 1px 0px 15px 0px;
  margin-top: 40px;
  box-shadow: 0px 0px 10px -1px rgba(0,0,0,0.37);
  -webkit-box-shadow: 0px 0px 10px -1px rgba(0,0,0,0.37);
  -moz-box-shadow: 0px 0px 10px -1px rgba(0,0,0,0.37);
}
.level-1 {
  background-color: rgb(164, 163, 163);
  text-align: center;
  padding: 1px 0px 15px 0px;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 25px;
}

/* ---------------- BOTON ACTUALIZAR MENU --------------------- */
.upload-menu {
  border-radius: 137px 135px 30px 134px;
  border: 1px solid green;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 85px;
  height: 85px;
  background: #4CAF50;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  transition: ripple 0.5s ease;
}

.upload-menu:hover {
  background: #4CAF50;
}

.upload-menu:active::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #4CAF50;
  animation: ripple 0.4s ease;
  border-radius: inherit;
}

@keyframes ripple {
  to {
    transform: scale(2);
    opacity: 0;
  }
}

/* ---------------- PRODUCTO COLAPSADO --------------------- */

.editor-styles-product {
  display: flex;
  width: 100%;
}

.productEditorStyles {
  display: flex;
  flex-direction: column;
}
.productEditorStyles.collapsed {
  overflow: hidden;
  padding: 3px;
}

.drag-collapse-editor {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.drag-collapse-editor.collapsed {
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
}
.collapsed-product-names {
  display: flex;
}

.drag-collapse {
  display: flex;
  justify-content: space-between;
  order: 1;
}

.drag-handle-icon {
  margin-right: 15px;
}


.product-info-div-collapsed {
  font-weight: 600;
  font-size: large;
}
.collapse-toggle {
  cursor: pointer;
}
/* ---------------------------  CATEGORA COLAPSADA  ------------------------------ */

.category-top-buttons {
  display: flex;
  justify-content: space-between;
}

.category-name-collapsed {
  font-size: x-large;
  font-weight: 700;
}


.trash.collapsed {
  margin-top: 30px;
  margin-left: 0;
}

.drag-handle-icon-category {
  margin: 10px 0 0 10px;
}



/* ---------------- CELULAR --------------------- */
/* --------------------------- MEDIA MOBILE SMALL DETAILS ------------------------------ */

@media (max-width: 991px) {
  .categories-container {
    margin: 10px 10px;
  }
  .category-names {
    justify-content: center;
    flex-direction: column;
    order: 1;
  }
  .category-names input {
    width: 100%;
  }
  .language-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: auto;
  }
  .menu-col-title {
    flex-direction: column;
    width: 100%;
  }

  .category-info .infos {
    display: flex;
    flex-direction: column;
    width: 100%;
    order: 1;
  }

  .add-price-type {
    order: 1;
  }

  .prices {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 50%;
  }
}

@media (max-width: 767px) {
  .productEditorStyles {
    align-items: center;
    margin: auto;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
  }
  .editor-styles-product {
    flex-direction: column;
    width: 100%;
  }

  .preview-image-modal-ref img {
    height: 100px;
    width: auto;
  }

  .detailsEditorStyles {
    flex-direction: column;
    width: 80%; /* Ajusta el ancho a tu preferencia */
    margin: 0 auto; /* Centra el elemento en la pantalla */
    order: 1;
  }

  .product-info {
    font-size: 0.8rem;
  }

  .product-name-input-ref {
    width: 100%;
    box-sizing: border-box;
  }

  .product-description-input-ref {
    margin: 5px 0px;
  }

  .editorActionsStyles {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    order: 3;
    height: auto;
    text-align: center;
    align-items: center;
  }
  .editorActionsStyles button {
    border: none;
  }

  .category-names {
    justify-content: center;
    order: 1;
  }

  .language-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .imageEditorStyles {
    order: 2;
    width: 50%; 
    margin: 0 auto; 
  }
}
