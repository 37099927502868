@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Barlow+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Ephesis&family=Smooch+Sans:wght@100;200;300;400;500;600&family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.epic-intro-container {
  position: relative;
  min-height: 100vh;
  text-align: center;
  color: #000000;
  font-family: 'Urbanist', sans-serif;
}

/*askjldkjlasdjkaksdasd*/
.yellow {
  background-color: #fdc029;
  height: 250px;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
}
.back {
  height: 50vh;
  width: 100%;
}

.grey {
  background-color: #ffffff;
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-top {
  background-color: #ffffff;
  width: 70%;
  height: 125%;
  margin: -300px 0 0 0;
  display: flex;
  flex-direction: row;
  box-shadow: 6px 8px 13px -5px rgba(99, 99, 99, 0.75);
  -webkit-box-shadow: 6px 8px 13px -5px rgba(99, 99, 99, 0.75);
  -moz-box-shadow: 6px 8px 13px -5px rgba(99, 99, 99, 0.75);
  border-radius: 10px;
}

.content-top-image {
  width: 50%;
  height: auto;
  float: right;
}

.content-top-image img {
  width: 90%;
  height: auto;
  float: right;
  margin-top: -20px;
  border-radius: 10px;
}

.content-top-text {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;
}
.content-top-text h1 {
  font-size: 1.7em;
  font-weight: 900;
}
.content-top-text p{
  font-size: 1em;
  font-weight: 500;
  color: #434a51;
  margin-top: 10px;
}

.form-button {
  background-color: #fdc029; 
  color: white; 
  font-weight: 700;
  font-size: large;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer; 
  transition: background-color 0.3s; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  border: none;
  margin-top: 25px;
}

.form-button:hover {
  background-color: #fdc029; 
  box-shadow: 6px 8px 13px -5px rgba(99, 99, 99, 0.75);
  -webkit-box-shadow: 6px 8px 13px -5px rgba(99, 99, 99, 0.75);
  -moz-box-shadow: 6px 8px 13px -5px rgba(99, 99, 99, 0.75);
}


@media (max-width: 1473px) {
  .content-top {
    width: 90%;
  }
}

@media (max-width: 1266px) {
  .content-top {
    width: 80%;
    height: 90%;
  }
  .content-top-image img {
    width: 84%;
    margin-top: -30px;
  }
}

@media (max-width: 1060px) {
  .content-top {
    width: 75%;
    height: 80%;
  }
  .content-top-image img {
    width: 85%;
    margin-top: -30px;
    float: none;
  }
  .content-top-text h1 {
    font-size: 1.4em;
   
  }
  .content-top-text p{
    font-size: 0.9em;
  }
  .form-button {
    font-size: small;
  }
}

@media (max-width: 960px) {

  .content-top {
    flex-direction: column;
    align-items: center;
    height: auto;
    margin-top: -200px;
  }
  .content-top-image {
     width: 80%;
  }

  .content-top-text {
    margin-top: 20px;
    width: 90%;
  }
  
  .content-top-text h1 {
    font-size: 1.5em;
   
  }
  .content-top-text p{
    font-size: 1em;
  }
}

@media (max-width: 450px) {
  .content-top {
    height: auto;
  }
  .content-top-image {
     width: 80%;
  }

  .content-top-text {
    margin-top: 20px;
    width: 90%;
  }
  
  .content-top-text h1 {
    font-size: 1.2em;
  }

  .content-top-text p{
    font-size: 0.9em;
  }
  .form-button {
    font-size: x-small;
  }
}


/*askjldkjlasdjkaksdasd*/

.info-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: #232121;
  padding: 50px;
}

.info-section-title{
  background-color: #232121;
  margin: 0;
  color: #ffffff;
  padding-top: 30px;
  margin-top: 20px;
}

.info-section-element {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-basis: calc(33.3333% - 20px);
}

.info-icon {
  background-color: #fdc029;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon {
  width: 40px;
  height: auto;
  color: #ffffff;
}

.info-section img {
  margin-bottom: 20px;
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.info-section img:hover {
  transform: scale(1.1);
}

.info-section h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
  transition: color 0.3s ease-in-out;
  font-weight: 800;
  color: #ffffff;
}

.info-section p {
  font-size: 1em;
  transition: color 0.3s ease-in-out;
  color: #c1c1c1;
}

.custom-image {
  width: 80px; 
  height: 80px;
  margin-bottom: 10px;  
  -webkit-box-shadow: 0px 0px 26px -9px rgba(0,0,0,1);
-moz-box-shadow: 0px 0px 26px -9px rgba(0,0,0,1);
box-shadow: 0px 0px 26px -9px rgba(0,0,0,1);
}

.cards-info {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;  
  flex-wrap: wrap;
}
.card-info {
  width: 300px;
  height: auto;
  margin: 20px;
}
.card-info a {
  text-decoration: none;
  color: #000000;
}

.footer {
  margin-top: 40px;
  padding: 20px;
  background-color: #232121;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.redes {
  color: #ffffff;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.redes a {
  color: #ffffff;
}
.instagram {
  width: 40px;
  height: 40px;
}
.facebook {
  width: 40px;
  height: 40px;
}
.footer-info {
  color: #ffffff;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
} 

.footer-mid {
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer-mid button {
  font-size: small;
  margin-top: 0;
  margin-bottom: 10px;
}

@media (max-width: 960px) {
    .info-section {
      flex-direction: column;
      align-items: center;
    }
    .info-section div {
        margin-bottom: 40px;
      }
      .top {
        flex-direction: column;
        align-items: center;
      }

      .top-right {
      display: none;
      }

      .intro-text,
      .top-img {
        max-width: 100%;
      }
      
    .form-button {
      margin-bottom: 40px;
    }

  }

.modal-content {
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    
  }
  
  .modal-header {
    background-color: #fdc029;
    color: #000000;
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .modal-title {
    font-size: 1.5rem;
    font-weight: 700;
  }
  
  .modal-body {
    padding: 20px;
  }
  
  .form-label {
    font-size: 1rem;
    font-weight: 600;
  }
  
  .form-control {
    margin-bottom: 15px;
    margin-top: 0px;
  }
  
  .form-control:focus {
    box-shadow: 0 0 5px #fdc029;
  }
  
  .btn-secondary {
    background-color: #6c757d;
    border: none;
  }
  
  .btn-secondary:hover {
    background-color: #5a6268;
  }
  
  .message-group {
    margin-bottom: 0px; 
  }
  
  .slider-container {
    width: 100%;
    overflow: hidden;
  }

  .card-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .card-info img {
    margin-bottom: 10px;
    width: 100%;
  }

  .card-info a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100% !important;
  }

  .card-info img {
    border-radius: 50%;
    object-fit: contain;
    width: 100px;
    height: 100px;
    max-width: 100%;
  }

  .card-info h3 {
    font-size: 1em;
  }


  @media (max-width: 600px) {
    .card-info img {
      width: 50px;
      height: 50px;
    }

    .card-info h3 {
      font-size: 0.8em;
    }
  }